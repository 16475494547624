@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mixins.scss';

.graphs {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 50%;
  height: 100%;
  top: 0;

  @media (max-width: 767px) {
    display: none;
  }

  .graph {
    color: rgba($royal-blue, 0.4);
    position: absolute;
    bottom: 0;
    right: 0;
    height: 0;

    &:after {
      background-color: rgba($royal-blue, 0.35);
      content: "";
      border-radius: .75rem 0 0 0;
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      top: 70px;
    }
    &:nth-child(1) {
      width: 70%;
    }
    &:nth-child(2) {
      width: 100%;
    }
  }
}
