@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mixins.scss';
@import '../../assets/styles/widget.scss';

.swap-picker {
  margin-bottom: 1rem;

  svg {
    max-width: 30px;
  }
  .label {
    display: none;
    font-size: .75rem;
    margin-bottom: 0;
    font-weight: 300;
    font-size: 13px;
    position: relative;
    top: 5px;
    opacity: 0;
    @include transition(0.15s);
  }
  .label-active {
    opacity: 1;
    @include transition(0.35s);
  }
  .label-error {
    opacity: 1;
    @include transition(0.35s);

    div {
      animation: shake 0.35s cubic-bezier(.51,.01,.3,1) forwards;
      transform: translate3d(0, 0, 0);
      color: darken($surf-pink, 30%);
    }
  }
  .group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: nowrap;
    width: 100%;
    height: 56px;

    .input {
      flex-grow: 1;
      flex-shrink: 1;
      min-width: 0;
      padding-left: 0;
      padding-right: 0;

      &::placeholder {
        color: $surf-gray-3;
        font-weight: 400;
        font-size: 1rem;
        text-overflow: ellipsis;
        opacity: 1;
      }
      &::-webkit-input-placeholder {
        color: $surf-gray-3;
        font-weight: 400;
        font-size: 1rem;
        text-overflow: ellipsis;
        opacity: 1;
      }
      &::-moz-placeholder, &:-moz-placeholder,&:-ms-input-placeholder {
        color: $surf-gray-3;
        font-weight: 400;
        font-size: 1rem;
        text-overflow: ellipsis;
        opacity: 1;
      }
      &:-ms-input-placeholder {
        color: $surf-gray-3;
        font-weight: 400;
        font-size: 1rem;
        text-overflow: ellipsis;
        opacity: 1;
      }

      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &[type=number] {
        -moz-appearance: textfield;
      }

      /* Firefox */
      &[type=number]::-webkit-outer-spin-button,
      &[type=number]::-webkit-inner-spin-button {
        opacity: 1;
      }
      &.disabled input {
        color: rgba(white, .4)
      }
    }
    .select {
      visibility: hidden;
    }
    .dropdown-button {
      flex-grow: 0;
      flex-shrink: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      min-width: 110px;

      & > * {
        flex-grow: 1;
      }

      .dropdown-button-icon {
        width: 2rem;
        margin-right: .5rem;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          height: 1.5rem;
        }
      }
      .dropdown-button-body {
        line-height: 1rem;
        margin-right: 1rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        span {
          display: block;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          margin-bottom: .0rem;
        }

        .title {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }

        svg {
          width: 20px;
          height: 20px;
          flex-shrink: 0;
        }
      }
      .dropdown-button-chevron {
        color: $white;
        opacity: 1;
        @include transition(0.15s);
        margin-left: .25rem;
        margin-right: .5rem;
      }
    }

    .dropdown-button:hover {
      .dropdown-button-chevron {
        opacity: 0.5;
        @include transition(0.35s);
      }
    }
  }
  .dropdown-menu {
    position: absolute;
    bottom: 0;
    height: auto;
    top: 100%;
    left: 0;
    right: 0;
    visibility: hidden;
    z-index: 10;
    opacity: 0;
    will-change: top;

    transition: opacity 0.3s cubic-bezier(0.375, 0.885, 0.6, 1) .5s, top 0.3s cubic-bezier(0.375, 0.885, 0.6, 1) 0s, visibility 0.3s cubic-bezier(0.375, 0.885, 0.6, 1) 1s;
    
    background: $surf-background-dark-primary;
    border-radius: .5rem;
    overflow: hidden;

    &:after {
      content: '';
      bottom: 0;
      left: 0;
      height: 1.5rem;
      width: 100%;
      // background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
      z-index: 1000;
      position: absolute;
    }

    .dropdown-menu-header {
      height: 56px;
      font-size: 0.75rem;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: .2rem;
      color: $surf-gray-3;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-grow: 0;
      flex-shrink: 0;
      z-index: 101;

      svg {
        color: none;
      }
    }
    .dropdown-menu-body {
      overflow: scroll;
      width: 100%;
      flex-grow: 1;
      display: flex;
      justify-content: stretch;
      align-items: stretch;

      &:before {
        content: '';
        background: linear-gradient(180deg, $bgColorPrimary 12%, rgba($bgColorPrimary, 0) 100%);
        top: 140px;
        height: 1rem;
        left: calc(2rem - 1px);
        pointer-events: none;
        position: absolute;
        right: calc(2rem - 1px);
        z-index: 100;
      }
      &:after {
        content: '';
        background: linear-gradient(rgba($bgColorPrimary, 0) 0%, $bgColorPrimary 100%);
        bottom: 2rem;
        height: 3rem;
        left: calc(2rem - 1px);
        pointer-events: none;
        position: absolute;
        right: calc(2rem + 10px);
        z-index: 100;
      }

      .dropdown-menu-list {
        position: relative;
        width: 100%;
        height: 100%;

        & > div {
          position: absolute;
          padding: 1rem 0;
          left: 0;
          right: 0;
        }
      }

      &::-webkit-scrollbar {
        pointer-events: none;
        display: none;
      }
      &::-webkit-scrollbar-track {
        pointer-events: none;
        display: none;
      }
      &::-webkit-scrollbar-thumb {
        pointer-events: none;
        display: none;
      }

      .option {
        height: 56px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: .5rem 0;
        margin-bottom: .875rem;
        cursor: pointer;

        &.option-disabled {
          &, &:hover, &:active {
            color: $tertiary;
            opacity: .3;
            cursor: default;
            pointer-events: none;
          }
        }
        &.option-placeholder {
          &, &:hover, &:active {
            cursor: default;
            pointer-events: none;
          }
        }

        .option-icon {
          width: 2.5rem;
          height: 2.5rem;
          margin-right: .75rem;
          border-radius: 1.25rem;
          overflow: hidden;
          background-color: lighten($surf-background-dark-primary, 5);
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 2.5rem;
            height: 2.5rem;
          }

          svg {
            height: 1.4rem;
            opacity: .25;
          }
        }

        .option-label {
        }

        .option-title {
        }
      }
    }
  }
  .dropdown-menu-active {
    visibility: visible;
    opacity: 1;
    top: 0;
    transition: opacity 0.2s cubic-bezier(0.375, 0.885, 0.6, 1) 0s, top 0.3s cubic-bezier(0.375, 0.885, 0.6, 1) 0s, visibility 0s cubic-bezier(0.375, 0.885, 0.6, 1) 0s;
  }

  .close-button {
    margin-right: -.5rem;
    padding: 0 !important;
    height: 24px;
  }
}
@keyframes shake {
  0%, 90% {
    transform: translate3d(0, 0, 0);
  }
  
  75% {
    transform: translate3d(2px, 0, 0);
  }

  50% {
    transform: translate3d(-3px, 0, 0);
  }

  25%{
    transform: translate3d(4px, 0, 0);
  }
}