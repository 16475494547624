@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mixins.scss';

.footer {
  font-size: 0.925rem;

  .container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 2rem 6rem;
    height: 120px;

    @media (max-width: 1199px) {
      padding: 2rem 4rem;
    }
    @media (max-width: 991px) {
      padding: 2rem 2rem;
    }
    @media (max-width: 767px) {
      padding: 1rem 1rem;
    }

    @media (max-width: 499px) {
      flex-direction: column;
    }
  }
  
  @media (max-width: 499px) {
    height: auto;
  }

  .copyright {
    color: $tertiary;

    @media (max-width: 500px) {
      text-align: center;
    }
  }
  .nav {
    margin-top: 0;
    margin-bottom: 0;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 1rem;

    .nav-item {
      margin-right: 3rem;
      line-height: inherit;
    }

    a {
      color: $white;
      white-space: nowrap;

      &:hover {
        color: $main;
      }
    }
    svg {
      margin-left: 1px;
      margin-right: 3px;
    }

    @media (max-width: 500px) {
      flex-direction: column;
      margin-bottom: 20px;

      .nav-item {
        margin-right: 15px;
        height: 40px;
        text-align: center;
      }
    }
  }
  .nav-content {
    justify-content: center;
    flex-direction: column;

    & > * {
      margin-bottom: 1.5rem;
    }
  }
  .dod {
    color: $surf-gray;
    opacity: .4;

    &:hover {
      opacity: 1;
      color: $surf-gray;
    }
  }
}
.container-center {
  justify-content: center !important;
}