/* Widget Theming */
$bgColorPrimary: var(--widget-bg-color-primary, #191E21);
$bgColorSecondary: var(--widget-bg-color-secondary, #1E2428);
$bgColorTertiary: var(--widget-bg-color-tertiary, #21282C);

$textColorPrimary: var(--widget-text-color-primary, #EFEFF0);
$textColorSecondary: var(--widget-text-color-secondary, #A4A6A8);
$textColorTertiary: var(--widget-text-color-tertiary, #494D51);

$accentColor: var(--widget-accent-color, #4963E6);
$iconsColor: var(--widget-icons-color, #FFFFFF);

:global {
    html.widget-theme {
        body {
            background-color: $bgColorPrimary  !important;
            color: $textColorPrimary  !important;
        }

        /* Widget bg classes */
        .widget-bg-primary {
            background-color: $bgColorPrimary  !important;
        }

        .widget-bg-secondary {
            background-color: $bgColorSecondary  !important;
        }

        .widget-bg-tertiary {
            background-color: $bgColorTertiary  !important;
        }

        /* Widget text classes */
        .widget-text-primary {
            color: $textColorPrimary  !important;
        }

        .widget-text-secondary {
            color: $textColorSecondary  !important;
        }

        .widget-text-tertiary {
            color: $textColorTertiary  !important;
        }

        /* Widget accent classes */
        .widget-accent-bg {
            background-color: $accentColor  !important;
        }

        .widget-accent-text {
            color: $accentColor  !important;
        }

        /* Widget icons classes */
        .widget-icons-bg {
            background-color: $iconsColor  !important;
        }

        .widget-icons-text {
            color: $iconsColor  !important;
        }

        .widget-input {
            input {
                background-color: $bgColorSecondary  !important;
                color: $textColorPrimary  !important;

                &:disabled {
                    background-color: $bgColorTertiary  !important;
                    color: $textColorSecondary  !important;
                }

                &:-webkit-autofill,
                &:-webkit-autofill:hover,
                &:-webkit-autofill:focus,
                &:-webkit-autofill:active {
                    -webkit-text-fill-color: $textColorPrimary  !important;
                    -webkit-box-shadow: 0 0 0 30px $bgColorSecondary inset !important;
                    -webkit-background-clip: text;
                }
            }

            &.surf-input {
                background-color: $bgColorSecondary  !important;

                label {
                    -webkit-backface-visibility: hidden;
                    color: $textColorPrimary  !important;
                }

                +div {
                    color: $textColorPrimary  !important;
                }
            }
        }

        .widget-button {
            background-color: $accentColor  !important;
            color: $textColorPrimary  !important;

            &:disabled {
                background-color: $bgColorTertiary  !important;
            }
        }

        .widget-summary-row {
            border-bottom-color: $bgColorTertiary  !important;
        }
    }

    .widget-panel {
        background-color: $bgColorSecondary  !important;
        margin: 1rem 2rem 1rem;

        &>div:first-child {
            padding: 1rem 1rem 0;
        }

        &>div:last-child {
            padding: 1rem 1rem 0 !important;
        }
    }
}

/* End Widget Theming */