@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mixins.scss';

.panel {
  flex-grow: 1;
  min-height1: 175px;
  background: $dark-alt;
  border-radius: 1rem;
  text-align: left;
  padding: 0;
  display: flex;
  flex-direction: column;

  .panel-header {
    flex-grow: 0;
    flex-shrink: 0;
    padding: 2rem 2rem 0;
    line-height: 1.5rem !important;
    color: $white;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    a {
      color: $gray;

      &:hover, &:active {
        color: $white;
      }
    }
    svg {
      vertical-align: middle;
      stroke: currentColor;
    }
  }

  .panel-body {
    padding: 2rem;
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;

    &.panel-body-collapse {
      overflow: hidden;
      @include transition(.75s);

      & > * {
        @include transition(.5s);
        opacity: 1;
      }
      
      &.panel-body-collapse-closed {
        height: 0;
        padding-top: 0.5rem !important;
        padding-bottom: 0.625rem !important;
        @include transition(1s);

        & > * {
          @include transition(.125s);
          opacity: 0;
        }
      }
    }
  }
  .panel-header + .panel-body {
    padding-top: 1.75rem;
  }
  &.panel-content {
    background1: $surf-background-dark-bw;
    
    .panel-header {
      padding: 1rem 1rem 0;
      color: $white;
    }
    .panel-body {
      padding: 1rem;
    }
  }
}