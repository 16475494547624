@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mixins.scss';

button.btn {
  height: 48px;
  padding: 0.5rem 1.125rem;
  border-radius: .75rem;
  @include transition(0.15s);
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;

  &.btn-blank {
    border-radius: .5rem;
    padding: 0;
    height: auto;
    min-height: 0;
    width: auto;
    min-width: 0;
  }

  &.btn-rounded {
    border-radius: 24px;
    padding: 0.5rem 1rem 0.5rem 1.25rem;
  }

  &.btn-lg {
    height: 56px;
    min-width: 132px;
    letter-spacing: -.02rem;
    padding: 0.325rem 1.25rem 0.5rem 1.25rem;
    border-radius: .75rem;

    &.btn-rounded {
      border-radius: 28px;
    }
  }
  
  &.btn-xl {
    height: 62px;
    min-width: 180px;
    font-size: 1.075rem;
    letter-spacing: -.02rem;
    border-radius: 12px;

    &.btn-rounded {
      border-radius: 31px;
    }
  }
  
  &.btn-icon {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    .icon-back {
      display: flex;
      position: relative;
      width: 20px;
      text-align: center;
      justify-content: center;
      align-items: center;
      
      &:after {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50%;
        margin-top: -10px;
        margin-left: -6px;
        content: "";
        width: 34px;
        height: 34px;
        z-index: 3;
        background-color: rgba($dark, .3);
        color: rgba($main, 0.3);
        transform: scale(.1) rotate(-90deg);
        background-image1: url("data:image/svg+xml,%3Csvg width='55' height='55' viewBox='0 0 55 55' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='27.5' cy='27.5' r='24.5' stroke='%23EB167A' stroke-width='3' stroke-linecap='round' stroke-linejoin='round' stroke-dasharray='3 17 6 1 11 8'%3E%3C/circle%3E%3C/svg%3E");
        background-size: 36px 36px;
        background-repeat: no-repeat;
        background-position: center center;
        @include transition(1.6s);
        opacity: 0;
      }
    }
    svg {
      width: 1.0625em;
      height: 1.0625em;
      margin-top: -2px;
      z-index: 5;
      position: relative;
      @include transition(0.4s);
    }
    &.btn-icon-before {
      svg {
        margin-right: .625rem;
      }
      
      .icon-back {
        margin-right: .625rem;
        svg {
          margin-right: 0;
        }
      }
    }
    &.btn-icon-after {
      svg {
        margin-left: .625rem;
      }
      .icon-back {
        margin-left: .625rem;
        svg {
          margin-left: 0;
        }
      }
    }
    &:hover {
      &, &:hover, &:focus, &:active {
        svg {
          @include transition(0.8s);
        }
        .icon-back {
          
          &:after {
            @include transition(0.8s);
            transform: scale(1);
            opacity: 1;
          }
        }
      }
    }
  }
  &.btn-clear {
    width: 1.25rem;
    height: 1.25rem;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $surf-background-dark-primary;
    border-color: $white;
    background-color: $white;
    @include transition(0.1s);

    &.active {
      opacity: 1;
      visibility: visible;
      @include transition(0.25s);
    }
    &:hover {
      background-color: rgba($white, 0.9);
      border-color: rgba($white, 0.9);
      color: $dark-alt;
    }
    &:focus, &:active {
      &, &:hover, &:focus, &:active {
        background-color: rgba($white, 0.9);
        border-color: rgba($white, 0.9);
        color: $dark-alt;
      }
    }
    &, &:hover, &:focus, &:active {
      &:disabled {
        background-color: #3B4043;
        border-color: #3B4043;
        pointer-events: none;
        color: $tertiary;
        cursor: default;
      }
    }
  }

  &.btn-animated-left {
    &:hover {
      &, &:hover, &:focus, &:active {
        svg {
          transform: translateX(-3px);
        }
      }
    }
  }
  &.btn-animated-right {
    &:hover {
      &, &:hover, &:focus, &:active {
        svg {
          transform: translateX(3px);
        }
      }
    }
  }
}

.btn {
  &.btn-primary {
    background-color: $main;
    border: none;
    @include transition(0.4s);
    position: relative;
    overflow: hidden;
    box-shadow1: 0px 2px 2px rgba($main, 0.0), 0px 4px 4px rgba($main, 0.0), 0px 8px 8px rgba($main, 0.0), 0px 16px 16px rgba($main, 0.0);

    &:after {
      content: none;
      position: absolute;
      z-index: 5;
      left: 0;
      top: 0;
      width: 0px;
      height: 0px;
      background: radial-gradient( circle closest-side, rgba($dark, 1), transparent );
      -webkit-transform: translate(calc(-50% + var(--x)), calc(-50% + var(--y)));
      -moz-transform: translate(calc(-50% + var(--x)), calc(-50% + var(--y)));
      -ms-transform: translate(calc(-50% + var(--x)), calc(-50% + var(--y)));
      transform: translate(calc(-50% + var(--x)), calc(-50% + var(--y)));
      -webkit-transition: height 0.2s ease;
      transition: height 0.2s ease;
      will-change: width,height,transform;
      mix-blend-mode: screen;
    }

    &:hover {
      background-color: adjust-hue(saturate(lighten($main, 7.5), 25), 1);
      border: none;
      @include transition(0.6s);
       
      &:after {
        width: 150px;
        height: 150px;
      }
    }
    &:focus {
      &, &:hover, &:focus, &:active {
        background-color: $main;
        border: none;
        box-shadow: 0 0 0 0.25rem rgba($main, .3);
        transform: translateY(0);
        @include transition(0.4s);
      }
    }
    &:active {
      &, &:hover, &:focus, &:active {
        background-color: desaturate(darken($main, 10), 15);
        border: none;
        box-shadow1: 0 0 0 0.25rem rgba($main, .3);
        transform: translateY(0);
        @include transition(0.4s);
      }
    }
    &, &:hover, &:focus, &:active {
      &:disabled {
        background-color: $surf-background-tertiary;
        border-color: $surf-background-tertiary;
        pointer-events: none;
        color: $gray;
        cursor: default;

        background-color: desaturate(darken($main, 15), 35);
        border: none;
        pointer-events: none;
        opacity: 0.5;
      }
    }
  }
  &.btn-white {
    background-color: $white;
    border-color: $white;
    color: $dark-alt;

    &:hover {
     background-color: rgba($white, 0.9);
     border-color: rgba($white, 0.9);
     color: $dark-alt;
    }
    &:focus, &:active {
      &, &:hover, &:focus, &:active {
        background-color: rgba($white, 0.9);
        border-color: rgba($white, 0.9);
        color: $dark-alt;
      }
    }
    &, &:hover, &:focus, &:active {
      &:disabled {
        background-color: $surf-background-tertiary;
        border-color: $surf-background-tertiary;
        pointer-events: none;
        color: $gray;
        cursor: default;
      }
    }
  }
  &.btn-default {
    background-color: $dark;
    border-color: $dark;
    color: $white;

    &:hover {
     background-color: desaturate(lighten($dark, 5), 0);
     border-color: desaturate(lighten($dark, 5), 0);
     color: $white;
    }
    &:focus, &:active {
      &, &:hover, &:focus, &:active {
        background-color: desaturate(lighten($dark, 7), 0);
        border-color: desaturate(lighten($dark, 7), 0);
        color: $white;
        box-shadow: 0 0 0 0.25rem rgba($gray, .3);
      }
    }
    &, &:hover, &:focus, &:active {
      &:disabled {
        background-color: $surf-background-tertiary;
        border-color: $surf-background-tertiary;
        pointer-events: none;
        color: $gray;
        cursor: default;
      }
    }
  }
  &.btn-transparent {
    &, &:focus, &:active {
      &, &:focus, &:active {
        background-color: transparent;
        border-color: transparent;
        box-shadow: none;
        color: $white;
      }
    }
    
    &:hover {
      &, &:hover, &:focus, &:active {
        background-color: $surf-background-tertiary;
        border-color: $surf-background-tertiary;
        pointer-events: none;
        color: $gray;
        cursor: default;
      }
    }
  }
}


.dropdown-toggle {
  &:after {
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 6L8 11L13 6' stroke='%2320262A' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    content: '';
    background-repeat: no-repeat;
    background-position: center -2px;
    width: 16px;
    display: inline-block;
    vertical-align: middle;
    height: 16px;
    margin-left: 8px;
    @include transition(0.15s);
    border: none;
  }

  &:hover:after {
    @include transition(0.35s);
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 6L8 11L13 6' stroke='%233888FF' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
}

.dropdown-menu {
  border-radius: 10px;
  border: none;
  overflow: hidden;
  padding: 0;
  box-shadow: 0 1px 2px 0 rgba(61,75,91,0.03),  0 2px 5px 0 rgba(61,75,91,0.05), 0 5px 12px 0 rgba(33,39,56,0.06), 0 15px 30px 0 rgba(33,39,56,0.1);

  .dropdown-item {
    padding: 0.5rem 1rem;

    &:first-child {
      padding-top: 0.75rem;
    }

    &:last-child {
      padding-bottom: 0.75rem;
    }
    
    &:hover {
      background-color: #F7F8FA;
    }
  }
}

