@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mixins.scss';

.metamask-button {
  color: $royal-blue !important;
  width: 100%;

  &.metamask-button-connected {
    color: white !important;

    svg {
      width: 28px !important;
    }

    .close-icon {
      opacity: 0;
      @include transition(0.15s);
    }
    &:hover {
      .close-icon {
        opacity: 1;
        @include transition(0.35s);
      }
    }
  }
}
.metamask-icon {
  width: 28px !important;
}