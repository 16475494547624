@import '../../../assets/styles/colors.scss';
@import '../../../assets/styles/mixins.scss';

.widget {
  padding: 2rem;
}
.metamask-button {
  color: $royal-blue !important;
  width: 100%;

  &.metamask-button-connected {
    color: white !important;

    svg {
      width: 28px !important;
    }
  }
  &.metamask-button-disconnect {
    color: white !important;
    background-color: $failure !important;

    svg {
      width: 28px !important;

      &, * {
        fill: white !important;
      }
    }
  }
}
.metamask-icon {
  width: 28px !important;
}

.transfer-page {
  margin: 0 auto;
  overflow: visible !important;

  & > div {
    align-items: stretch;
    
    & > div {
      height: auto;
      display: flex;
      align-items: stretch;
      justify-content: stretch;
    }
  }

  .card-page-title {
    min-height: 100px;
  }
  .card-page-description {
    margin-top: 1rem;
    margin-bottom: 2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;

    &.card-page-description-active {
      -webkit-line-clamp: unset;
      line-clamp: unset;
    }

    div {
      margin-right: .25rem;
    }

  }
  .more-button {
    position: relative;
    left: 0;
    top: -2rem;
    cursor: pointer;
  }
  .card {
    margin-bottom: 2rem;
    min-height: 180px;
    background: #DF6F66 !important;
    cursor: default;
    display: flex;
    justify-content: stretch;
    align-items: stretch;

    .main-flex {
      flex-grow: 1;
      height: auto;
    }

    &.card-loading {
      background: $dark-gray !important;
    }
  }
  .card-page-cta {
    width: 100%;
    align-self: stretch;
  }
  .card-page-input, .card-small {
    margin-bottom: 1rem;
  }
  .card-page-select {
    margin-bottom: 2rem;
  }
  .card-page-slider-slide {
    padding: 0 .5rem;
  }
  .panel {
    background-color: transparent;
  }
}

.card-small {
  border-radius: .75rem;
  border: 1px solid rgba(#ffffff, .02);
  height: 56px;
  padding: 1rem;
  padding-right: 3rem;
  position: relative;
  cursor: pointer;

  &:after {
    position: absolute;
    width: 20px;
    height: 14px;
    right: 1rem;
    top: 1.25rem;
    content: "";
    background: #DF6F66;
    border-radius: 2px;
  }
}

.card-summary {
  flex-grow: 1;

  .card-summary-row {
    border-top: 1px solid rgba(#ffffff, .07);
    min-height: 56px;
    padding-top: .825rem !important;
    padding-bottom: .825rem !important;

    &:first-child {
      border-top: none;
    }
    dt {
      margin-right: .75rem;
      max-width: 50%;
    }
    dd {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  dt, dd {
    margin: 0;
  }
}
.button-back {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.button-cta {
  margin-top: 1rem;
}
.button-icon {
  margin-right: .5rem;
}
.panel-content {
  & > *:last-child {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  & > * {
    // padding-left: 0rem !important;
    // padding-right: 0rem !important;
  }
  margin-bottom: 1rem;
}

.transaction-receipt {
  & > button {
    margin-top: 0;
  }
}
.console-ouput {
  background-color: $surf-background-darken;
  padding: .75rem 1rem;
  border-radius: .5rem;
  color: $surf-text-dark-tertiary;
  font-size: .925rem;

  .title {
    color: $surf-negative;
  }
}

.status-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 300px;
  background-color: $surf-background-dark-primary;
  background-repeat: no-repeat;
  background-position: center;
  padding: 2rem;
  padding-top: 9rem;
  z-index: 9999;
  border-radius: 1.5rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  @include transition(0.15s);

  &.active {
    @include transition(0.3s);
    opacity: 1;
    pointer-events: all;
  }

  &.completed {
    background-image: url("data:image/svg+xml,%3Csvg width='49' height='48' viewBox='0 0 49 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='24.5498' cy='24' r='24' fill='%2326DA72'/%3E%3Cpath d='M16.5498 24L23.0498 30L34.5498 18' stroke='%23EFEFF0' stroke-width='1.75' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
  &.failed {
    background-image: url("data:image/svg+xml,%3Csvg width='49' height='48' viewBox='0 0 49 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='24.5498' cy='24' r='24' fill='%23FF4747'/%3E%3Cpath d='M16.5498 32L24.5498 24L16.5498 16' stroke='%23EFEFF0' stroke-width='1.75' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M32.5498 32L24.5498 24L32.5498 16' stroke='%23EFEFF0' stroke-width='1.75' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
}

.disclaimer {
  color: #FF4747;
  background-color: #1E2428;
  border-radius: 1rem;
  padding: 0rem;
  font-size: .925rem;
  
  svg {
    margin-right: 1rem;
  }
}