@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mixins.scss';

.wallet-list {
  padding-top: 2rem;
  padding-bottom: 2rem;

  &.wallet-list-empty {
    color: $gray;
  }
}
.pattern-connect {
  position: absolute !important;
  z-index: 999;
  top: 2rem;
  right: 2rem;
  min-width: 170px;

  .tooltip-container {
    & > * {
      cursor: pointer;
    }
  }

  @media (max-width: 767px) {
    top: 1rem;
    right: 5.5rem;
  }
  @media (max-width: 575px) {
    top1: 2rem;
    right1: 1rem;
  }
  @media (max-width: 399px) {
    top1: 1.25rem;
    right1: 0.75rem;
  }

  .pattern-connect-button {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    @include transition(0.15s);
    pointer-events: none;
    opacity: 0;
    
    &.pattern-connect-button-active {
      pointer-events: all;
      opacity: 1;
    }
  }
}
.modal-connect {
  & > * {
    max-width: 360px !important;

    & > * {
      padding: 1rem;
      & > *:first-child {
        padding: .25rem;
      }
    }
  }
}
.extension {
  padding: .5rem 0;
  margin-bottom: 1rem;
  cursor: pointer;
  color: $white;

  &.extension-disabled {
    color: $surf-background-dark-neutral !important;
    cursor: default;

    .extension-address {
      color: $surf-background-dark-neutral !important;
    }
    .extension-icon {
      svg {
        rect, path:first-child {
          fill: $surf-background-dark-neutral !important;
          stroke: $surf-background-dark-neutral !important;
        }
        path {
          fill: $gray !important;
          stroke: $gray !important;
        }
      }
    }
  }
  
  .extension-icon {
    border-radius: 1rem;
    margin-right: .5rem;
    overflow: hidden;
  }
  .extension-address {
    color: $text-secondary;
  }
}
.pattern {
  padding: 0.325rem 0.25rem 0.5rem 0.75rem;
  height: 56px;
  border-radius: 0.75rem;
  line-height: 1rem !important;
  background-color: $secondary;
  border-color: $secondary;
  color: $white;

  .pattern-address {
    color: $text-secondary;
  }
}


.wallet-list {
  padding-top: 2rem;
  padding-bottom: 2rem;

  &.wallet-list-empty {
    color: $gray;
  }
}
.modal-connect {
  & > * {
    max-width: 360px !important;

    & > * {
      & > *:first-child {
        padding: .25rem;
      }
    }
  }
}
.extension {
  padding: .5rem 0;
  margin-bottom: 1rem;
  cursor: pointer;

  &.extension-disabled {
    color: $surf-background-dark-neutral !important;

    .extension-address {
      color: $surf-background-dark-neutral !important;
    }
    .extension-icon {
      svg {
        rect, path:first-child {
          fill: $surf-background-dark-neutral !important;
          stroke: $surf-background-dark-neutral !important;
        }
        path {
          fill: $gray !important;
          stroke: $gray !important;
        }
      }
    }
  }
  
  .extension-icon {
    border-radius: 1rem;
    margin-right: .5rem;
    overflow: hidden;
  }
  .extension-address {
    color: $text-secondary;
  }
}
.pattern {
  padding: 0.325rem 0.25rem 0.5rem 0.75rem;
  height: 56px;
  border-radius: 0.75rem;
  line-height: 1rem !important;
  background-color: $secondary;
  border-color: $secondary;
  color: $white;

  .pattern-address {
    color: $text-secondary;
  }
}