@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mixins.scss';

.status {
  &.status-awaiting {
    color: $surf-dark-text-neutral;
  }
  &.status-pending {
    color: $surf-warning;
  }
  &.status-success {
    color: $surf-positive;
  }
  &.status-error {
    color: $surf-negative;
  }
}