@import './colors.scss';
@import './mixins.scss';

html {
  font-size: 16px;
}

body {
  font-family: 'Manrope', sans-serif;
  font-weight: 500;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  letter-spacing: -.01em;
  background-color: $surf-background-dark-primary;
  color: $white
}

a {
  color: $main;
  @include transition(0.15s);

  &:hover {
    color: lighten($main, 5);
    @include transition(0.3s);
  }

  &, &:focus, &:active, &:hover {
    text-decoration: none;
  }
  &:focus, &:active, &:hover {
    @include transition(0.35s);
  }
  &.active {
    color: $main;
  }
  &:focus-visible {
    // &, &:focus, &:active, &:hover {
      border-radius: .25rem;
      outline: 2px solid rgba($main, 0.5);
    // }
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Manrope', sans-serif;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 0;
}

html, body, #root, .ws-app {
  /* IE 10-11 didn't like using min-height */
  height: 100%;
}
.ws-app {
  display: flex;
  flex-direction: column;
}

.container, .container-fluid {
  // max-width: 1020px;
  // padding-left: 16px;
  // padding-right: 16px;
}

main {
  flex-grow: 1;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
}

.align-left {
  text-align: left;
}
.align-center {
  text-align: center;
}
.align-right {
  text-align: right;
}

.color-white {
  color: $white;
}
.color-faded {
  color: $text-secondary;
}
.color-warning {
  color: $surf-negative-light;
}

.widget-wrapper {
  overflow: hidden;
}

.content-container {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 2rem 7rem;

  @media (max-width: 1199px) {
    padding: 2rem 5rem;
  }
  @media (max-width: 991px) {
    padding: 2rem 3rem;
  }
  @media (max-width: 767px) {
    padding: 1rem 2rem;
  }
}

.main {
  min-height: 800px;
  
  h1 {
    background: linear-gradient(96.02deg, #506DFD 0%, #769FFF 89.06%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    max-width: 700px;
    margin-bottom: 2rem;
    position: relative;
    z-index: 10;

    @media (max-width: 767px) {
      font-size: calc(10vw) !important;
      line-height: calc(15vw) !important;
    }
  }
  h1 + div {
    position: relative;
    z-index: 10;

    span {
      white-space: nowrap;
    }
  }
  h1 + * > span > svg {
    transform: translateY(4px);
    margin: 0 3px;
  }
  .visual-container {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;

    @media (max-width: 767px) {
      opacity: .5 !important;
    }

    .visual {
      background-color: transparent;
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-300px);

      svg {
        position: absolute;
        pointer-events: none;
        -webkit-transform: translate(-50%,-50%);
            -ms-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);

      @for $i from 15 through 1 {
          &:nth-child(#{$i}) {
            opacity: #{(16 - $i) * (16 - $i) * (16 - $i) * 0.06666666666 *0.0666666666 * 0.06666666666};
            transform: rotate(#{($i - 1)  * (-4.1)}deg);
          }
        }
        &:nth-child(1) {
          opacity: 1;
        }
      }
    }
  }
}
.swap-widget {

  @media (max-width: 767px) {
    margin: 0 auto;
  }
}