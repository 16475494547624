@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mixins.scss';

.form-control {
  position: relative;
  min-width: auto;
  min-width: 0px;
  margin-bottom: .5rem;

  .form-control-title {
    margin-bottom: .25rem;
    @include transition(0.15s);
  }

  .form-control-caption {
    margin-top: .25rem;
    margin-left: 1rem;
    margin-right: 1rem;
    color: rgba(#ffffff, .42);
    height: 0.75rem;
  }
  &.form-control-status-error {
    .form-control-caption {
      color: $failure;
    }
  }
  &.form-control-status-success {
    .form-control-caption {
      color: $success;
    }
  }
  &.form-control-status-validate {
    .form-control-caption {
      color: rgba(#ffffff, 1);
    }
  }
}