@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mixins.scss';

.overlay {
  &, & > *, & > * > * {
    background-color: $dark-alt;
    & > * {
      padding: 0;
    }
  }
}

.panel {
  position: relative;
  flex-grow: 0;
  min-height: 384px;
  margin-bottom: 32px;
  color: $dark-gray;
  cursor: pointer;

  &, & > * {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & > * {
    position: relative;
  }

  &:before {
    content: "";
    background-color: $dark;
    position: absolute;
    border-radius: .75rem;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    @include transition(0.2s);
  }

  &:hover {
    &:before {
      @include transition(0.5s);
      top: -1rem;
      bottom: -1rem;
      left: -1rem;
      right: -1rem;
    }
  }

  @media (max-width: 575px) {
    flex-grow: 1;
    margin-right: 0;
    width: 100%;

    // & > div {
    //   padding-bottom: 0 !important;
    // }
  }

  &.panel-half {
    min-height: 160px;
    @include transition(.3s);
  }

  svg {
    @include transition(.3s);
  }
  .panel-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: white;
    opacity: 0;
    @include transition(.3s);

    & > * {
      padding: 2rem;

      @media (max-width: 575px) {
        padding1: 0;
      }

      div {
        border-radius: 1rem;
        border: 1px solid #ffffff;
        padding: .25rem .5rem;
        white-space: nowrap;
      }
    }
  }
  &:hover {
    .panel-overlay {
      opacity: 1;
    }
    &.panel-half {
      color: #292F32;

      svg {
        @include transition(.3s);
        fill: #292F32;
      }

      .loader-dots {
        div {
          background-color: #292F32;
        }
      }
    }
    &:not(.panel-half) {
      svg {
        @include transition(.3s);
        fill: white;
        color: white;
      }
    }
  }
}

.panel-column {
  width: calc(33% - 18px);
  margin-right: 32px;

  @media (max-width: 767px) and (min-width: 575px) {
    width: calc(50% - 16px);
  }

  @media (max-width: 575px) {
    width: 100%;
    margin-right: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.content-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 2rem 7rem;

  .panel-wrapper {
    max-width: 980px;
    min-width: 980px;
    margin: 0 auto;

    @media (max-width: 1199px) {
      max-width: 2000px;
      min-width: 0px;
      width: 100%;
    }
    &.panel-wrapper-two-cols {
      display: none;
    }

    @media (max-width: 767px) and (min-width: 575px) {
      display: none;

      &.panel-wrapper-two-cols {
        display: flex;
      }
    }

    @media (max-width: 575px) {
      flex-direction: column !important;
    }
  }

  @media (max-width: 1199px) {
    padding: 2rem 5rem;
  }
  @media (max-width: 991px) {
    padding: 2rem 3rem;
  }
  @media (max-width: 767px) {
    padding: 2rem 2rem;
  }
  
  h1 {
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 4rem;

    @media (max-width: 575px) {
      font-size: 11.2vw !important;
    }
  }
}
.loader-dots {
  line-height: .25rem;
  font-size: .5rem;
}
.dots-preloader {
  line-height: .25rem;
}

.isMobile {
  .panel {
    &:hover {
      &:before {
        top: 0rem;
        bottom: 0rem;
        left: 0rem;
        right: 0rem;
      }
    }
    .panel-overlay {
      opacity: 1;
    }
    &:not(.panel-half) {
      svg {
        @include transition(.3s);
        fill: white !important;
        color: white !important;
      }
    }
    // &.panel-half {
    //   min-height: 190px;
    //   justify-content1: flex-end !important;
    //   align-items1: flex-start !important;

    //   .panel-overlay {
    //     justify-content1: space-between !important;
    //     align-items: flex-start !important;
    //   }
    //   svg {
    //     transform: translateY(20px);
    //   }
    //   .price-ever {
    //     transform: translateY(20px);
    //   }
    // }
  }
  h1 {
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 3rem;

    @media (max-width: 575px) {
      font-size: 11.2vw !important;
    }
  }
}