@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mixins.scss';

.buy-widget {
  
}

.swiper-slide {
  height: auto;

  .slider-slide-panel {
    height: 100%;
  }
}

.qrcode {
  width: 230px;
  height: 230px;
  border-radius: 1rem;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 230px;
    height: 230px;
    transform: translateY(-.5rem);
  }
}
.qrcode-desc {
  white-space: pre;
  padding-top: 0rem;
  padding-bottom: 1rem;
  color: $gray-extralight;
}

.swap-confirmed, .send-confirmed {
  & > div:last-child {
    justify-content: flex-end;
    align-items: center;
  }
}

.swiper-button-wrapper {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: flex-start;

  & > button {
    flex-grow: 1;
  }
}

.swap-summary, .send-summary {
  flex-grow: 1;

  .swap-summary-row,.send-summary-row {
    border-bottom: 1px solid lighten($surf-background-dark-primary, 7);
    line-height: 4rem;

    &:last-child {
      border-bottom: none;
    }
    dd {
      flex-grow: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-left: 1rem;
    }
  }
  dt, dd {
    margin: 0;
  }

  dd {
    word-wrap: break-word;
  }
}

.form {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  & > * {
    width: 100%;
  }

  
  .fees {
    margin-top: 1.5rem;
  }
  .rate {
    cursor: pointer;
    margin-top: .5rem;
    margin-bottom: 2.5rem;
  }
}

.icon-back {
  margin-right: .5rem;
}

.result {
  position: absolute;
  background-color: white;
  top: 10px;
  bottom: 10px;
  left: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  
  .result-header {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;

    flex-grow: 1;
    font-size: 22px;
    font-weight: 600;
    color: '#000000';

    svg {
      display: block;
      margin: 0 auto 1rem;
    }
  }

  .result-footer {
    flex-grow: 0;
    padding-bottom: 1rem;
  }
}
.tooltip {
  &.show {
    opacity: 1;
  }

  .arrow {
    margin: 0 auto;
    left: 50%;
    margin-left: -10px;
    position: absolute;
    display: block;
    width: .8rem;
    height: .4rem;
    bottom: 0;
  }
  .tooltip-inner {
    min-width: 250px;
    border-radius: 20px;
    padding: 20px 25px;
    -webkit-backdrop-filter: blur(.5rem);
    backdrop-filter: blur(.5rem);
    background-color: rgba(#000000, 0.85);
    font-size: .935rem;
    font-weight: 300;
    text-align: left;

    a {
      font-weight: 400;
    }

    p {
      margin-bottom: 5px;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }
}

.btn-primary {
  svg {
    display: none;
    opacity: 0;
  }
  &.btn-loading {
    svg {
      display: inline-block;
      opacity: .6;
    }
  } 
}

.status-loader {
  width: 64px;
  height: 64px;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: .5rem;

  &.status-loader-inprogress {
    background-color: #4259CF;
  }

  svg, svg * {
    stroke-width: 3px;
    opacity: 1;
    stroke: #fff;
  }
}

$spinnerSize: 40;

.spinner {
  display: inline-block;
  margin-right: .5rem;
  margin-left: -2rem;
  opacity: .6;
  mix-blend-mode: multiply;
  width: $spinnerSize + px;
  height: $spinnerSize + px;
  x: 0px;
  y: 0px;
  viewBox: 0 0 $spinnerSize $spinnerSize;
  
  circle {
    fill: transparent;
    stroke: #2785b3;
    stroke-width: 6;
    stroke-linecap: round;
    stroke-dasharray: (3.14 * $spinnerSize);
    -webkit-transform-origin: (0.5px * $spinnerSize) (0.5px * $spinnerSize) 0;
    -moz-transform-origin: (0.5px * $spinnerSize) (0.5px * $spinnerSize) 0;
    -ms-transform-origin: (0.5px * $spinnerSize) (0.5px * $spinnerSize) 0;
    -o-transform-origin: (0.5px * $spinnerSize) (0.5px * $spinnerSize) 0;
    transform-origin: (0.5px * $spinnerSize) (0.5px * $spinnerSize) 0;
    -webkit-animation: spinner 3.5s linear infinite;
    -moz-animation: spinner 3.5s linear infinite;
    -ms-animation: spinner 3.5s linear infinite;
    -o-animation: spinner 3.5s linear infinite;
    animation: spinner 3.5s linear infinite;
  }
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    stroke-dashoffset: (1.4 * $spinnerSize);
  } 50% {
    -webkit-transform: rotate(720deg);
    stroke-dashoffset: (3.14 * $spinnerSize);
  } 100% {
    -webkit-transform: rotate(1080deg);
    stroke-dashoffset: (1.4 * $spinnerSize);
  }
}

@-moz-keyframes spinner {
  0% {
    -moz-transform: rotate(0deg);
    stroke-dashoffset: (1.4 * $spinnerSize);
  } 50% {
    -moz-transform: rotate(720deg);
    stroke-dashoffset: (3.14 * $spinnerSize);
  } 100% {
    -moz-transform: rotate(1080deg);
    stroke-dashoffset: (1.4 * $spinnerSize);
  }
}

@-ms-keyframes spinner {
  0% {
    -ms-transform: rotate(0deg);
    stroke-dashoffset: (1.4 * $spinnerSize);
  } 50% {
    -ms-transform: rotate(720deg);
    stroke-dashoffset: (3.14 * $spinnerSize);
  } 100% {
    -ms-transform: rotate(1080deg);
    stroke-dashoffset: (1.4 * $spinnerSize);
  }
}

@-o-keyframes spinner {
  0% {
    -o-transform: rotate(0deg);
    stroke-dashoffset: (1.4 * $spinnerSize);
  } 50% {
    -o-transform: rotate(720deg);
    stroke-dashoffset: (3.14 * $spinnerSize);
  } 100% {
    -o-transform: rotate(1080deg);
    stroke-dashoffset: (1.4 * $spinnerSize);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
    stroke-dashoffset: (1.4 * $spinnerSize);
  } 50% {
    transform: rotate(720deg);
    stroke-dashoffset: (3.14 * $spinnerSize);
  } 100% {
    transform: rotate(1080deg);
    stroke-dashoffset: (1.4 * $spinnerSize);
  }
}