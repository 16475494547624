@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mixins.scss';

.widget {
  overflow1: hidden;
  background: $dark-alt;
  border-radius: 0.75rem;
  box-shadow: 0px 32px 48px 0px #161A1D;
  width: 350px;
  min-height: 175px;
  background: $dark-alt;
  text-align: left;
  padding: 0;

  @media (max-width: 480px) {
    width: 100%;
  }
}