@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mixins.scss';


.input-label {
  position: absolute;
  height: 56px;
  top: 0;
  left: 1rem;
  padding: 1rem 0;
  pointer-events: none;
  color: $gray;
  z-index: 9999;
  transform-origin: left center;
  @include transition(0.15s);
}
  .input-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    align-items: stretch;
    position: relative;
    flex-wrap: nowrap;
    width: 100%;
    height: 56px;
    background-color: $dark;
    border-radius: .75rem;
    @include transition(0.15s);

    &.input-group-focused {
      box-shadow: 0 0 0 3px rgba(saturate(adjust-hue(lighten($main, 5), 10), 20), .5);
      @include transition(0.3s);
    }

    &.input-has-clear {
      padding-right: 1.5rem;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus,
    input::-webkit-autofill-selected,
    input::-webkit-autofill-selected:hover, 
    input::-webkit-autofill-selected:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      caret-color: white !important;
      background-color: $dark !important;
      -webkit-text-fill-color: $white;
      -webkit-box-shadow: 0 0 0px 1000px $dark inset;
      transition: background-color 5000s ease-in-out 0s;
    }

    .input-units {
      padding: 1rem 1rem 1rem 0rem;
      height: 56px;
      position: absolute;
      top: 0;
      right: 0;
    }

    .input-action {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-right: 1rem;
      height: 56px;
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;

      &, * {
        white-space: nowrap;
      }
    }

    .input-clear {
      position: absolute;
      top: 0;
      right: 0;
      height: 56px;
      border-radius: .5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      padding-right: .875rem;
      padding-left: .5rem;
      opacity: 0;
      pointer-events: none;
      z-index: 2;

      button {
        cursor: pointer;
        height: 1rem;
        width: 1rem;
        border-radius: .5rem;
        background-color: $white;
        border-color: $white;
        color: $dark;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
      }
    }

    .input-units, .input-clear {
      color: $gray;
    }

    .input-component {
      flex-grow: 1;
      flex-shrink: 1;
      min-width: 0;
      padding: 0;
      padding-left: 1rem;
      padding-bottom: 0.125rem;
      padding-right: 1rem;
      overflow: hidden;
      text-overflow: ellipsis;
      transition: 0.2s cubic-bezier(0.375, 0.885, 0.6, 1) all,  0s cubic-bezier(0.375, 0.885, 0.6, 1) letter-spacing,  0s cubic-bezier(0.375, 0.885, 0.6, 1)  font-family;
      background-color: $surf-background-dark-bw;
      border-radius: .75rem;
      border: none;
      color: $white;
      box-shadow: none;
      outline: none;
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.5rem;

      &::placeholder {
        color: $gray;
        text-overflow: ellipsis;
        opacity: 1;
      }
      &::-webkit-input-placeholder {
        color: $gray;
        text-overflow: ellipsis;
        opacity: 1;
      }
      &::-moz-placeholder, &:-moz-placeholder,&:-ms-input-placeholder {
        color: $gray;
        text-overflow: ellipsis;
        opacity: 1;
      }
      &:-ms-input-placeholder {
        color: $gray;
        text-overflow: ellipsis;
        opacity: 1;
      }

      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &[type=number] {
        -moz-appearance: textfield;
      }

      /* Firefox */
      &[type=number]::-webkit-outer-spin-button,
      &[type=number]::-webkit-inner-spin-button {
        opacity: 1;
      }
      &[type=password]:not(:placeholder-shown) {
        letter-spacing: 0rem;
        font-family: Verdana;
        transition: 0.2s cubic-bezier(0.375, 0.885, 0.6, 1) all,  0s cubic-bezier(0.375, 0.885, 0.6, 1) letter-spacing,  0s cubic-bezier(0.375, 0.885, 0.6, 1)  font-family;
      }
    }
    .input-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 1.25rem;
      flex-basis: 0;
      flex-grow: 0;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 3.25rem;
      pointer-events: none;
      color: $gray;
    }

    &.input-group-active {
      .input-clear {
        opacity: 1;
        pointer-events: all;
      }
      .input-clear + * + .input-units, .input-clear + .input-units {
        opacity: 0;
      }
    }
  }
  .input-caption {
    margin-top: .25rem;
    margin-left: 1rem;
    color: rgba(#ffffff, .42);
  }
  .input-validation {
    .input-caption {
      color: $white;
    }
  }  Ъ

  .input-error {
    .input-component,
    .input-caption {
      color: $red !important;
    }
  }
  .input-error {
    .input-caption {
      color: $green;
    }
  }
  .input-disabled {
    &.input-group {
      background-color: $surf-background-dark-tertiary;
    }
    .input-units {
      color: $gray !important;
    }
    .input-action {
      color: $gray !important;
    }
    
    .input-component {
      background-color: $surf-background-dark-tertiary;
      color: $gray !important;

      &::placeholder {
        color: $gray !important;
        opacity: 1;
      }
      &::-webkit-input-placeholder {
        color: $gray !important;
        opacity: 1;
      }
      &::-moz-placeholder, &:-moz-placeholder,&:-ms-input-placeholder {
        color: $gray !important;
        opacity: 1;
      }
      &:-ms-input-placeholder {
        color: $gray !important;
        opacity: 1;
      }
    }
  }

  .input-icon {
    .input-component {  
      padding-left: 2.825rem;
    }
  }
  .input-substitute {}
  .input-slide {
    .input-component {
      padding-top: 1rem;
      opacity: 0;
    }

    &.input-group-active, &.input-group-focused {
      .input-label {
        transform: scale(.75) translateY(-.375rem);
        transform-origin: 0 0;
        @include transition(0.15s);
      }
      .input-component {
        opacity: 1;
        @include transition-property(0.15s, opacity, .2s)
      }
    }
  }
  .input-duplet {
    .flex-container {
      flex-grow: 1;
    }
    .input-label {
      transform: scale(.75) translateY(-.375rem);
      transform-origin: 0 0;
      @include transition(0.15s);
      color: $white;
    }
    .input-component {
      padding-top: 1rem;
      opacity: 1;
      @include transition-property(0.15s, opacity, .2s)
    }
    &.input-group-active, &.input-group-focused {
      .input-label {
        color: $gray;
      }
      .input-component {
        color: $white;
      }
    }
  }
    .input-group:not(.input-group-focused):not(.input-group-active):hover {
      .input-label {
        color: $text-secondary;
      }
      .input-component {

        &::placeholder {
          color: $text-secondary;
          @include transition(0.3s);
        }
        &::-webkit-input-placeholder {
          color: $text-secondary;
          @include transition(0.3s);
        }
        &::-moz-placeholder, &:-moz-placeholder,&:-ms-input-placeholder {
          color: $text-secondary;
          @include transition(0.3s);
        }
        &:-ms-input-placeholder {
          color: $text-secondary;
          @include transition(0.3s);
        }
      }
    }

@keyframes shake {
  0%, 90% {
    transform: translate3d(0, 0, 0);
  }
  
  75% {
    transform: translate3d(2px, 0, 0);
  }

  50% {
    transform: translate3d(-3px, 0, 0);
  }

  25%{
    transform: translate3d(4px, 0, 0);
  }
}