@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mixins.scss';

.form {
  .rate {
    margin-top: .5rem !important;
    margin-bottom: 2rem !important;
  }
  .input, .button {
    margin-bottom: 1rem;
  }
}

.qrcode {
  width: 210px;
  height: 210px;
  background-color: $white;
  border-radius: 1rem;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-.5rem);
  margin-bottom: 1rem;

  svg {
    width: 170px;
    height: 170px;
    fill: $dark;
    color: $dark;
    transform: translateY(0);
  }
}

.swap-summary {
  .swap-summary-row {
    &:nth-child(2) {
      border-bottom: none;
      margin-bottom: .5rem;
    }
  }
}
.card-date-row {
  div {
    width: calc(50% - .5rem);
  }
}
.input-card-number input {
  letter-spacing: .0rem;
}